import { useState } from 'react'
import ImgPreview from './ImgPreview'

function AddPortfolioInput({ order, onInputChange, onDelete, isEdit }) {
  const [portfolioInputList, setPortfolioInputList] = useState([])

  const updatePortfolioInput = (imgSrc) => {
    onInputChange('imgUrl', imgSrc)
    setPortfolioInputList((prev) => ({
      ...prev,
      imgUrl: imgSrc,
      order: order,
    }))
  }

  return (
    <div className='bg-sk-box002 p-[30px] flex flex-col gap-[20px] rounded-[10px] shadow-sk '>
      <div className='flex flex-col gap-[20px] justify-center'>
        <div className='flex justify-end'>
          <button
            type='button'
            onClick={onDelete}
            className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
          >
            X
          </button>
        </div>
        <div className='flex items-center'>
          <ImgPreview
            label={`이미지 `}
            isEdit={isEdit}
            itemImg={portfolioInputList.imgUrl}
            onImageChange={(imgUrl) => updatePortfolioInput(imgUrl)}
            imgSize={`w-full h-full`}
          />
        </div>
        <div className='flex flex-col gap-[8px]'>
          <div className='flex gap-[8px] items-center'>
            <div className='flex gap-[12px]'>
              <p className='text-body2-bold min-w-[30px] text-sk-info'>제목</p>
            </div>
            <input
              type='text'
              className={` ${
                isEdit
                  ? 'hidden'
                  : 'border rounded-[10px] w-[402px] bg-sk-box002 px-[15px] py-[10px] text-body2-medium'
              }`}
              onChange={(e) => {
                setPortfolioInputList({
                  ...portfolioInputList,
                  title: e.target.value,
                })
                onInputChange('title', e.target.value)
              }}
              required
            />
          </div>
          <div className='flex gap-[8px] items-center'>
            <div className='flex gap-[12px]'>
              <p className='text-body2-bold min-w-[30px] text-sk-info'>내용</p>
            </div>
            <input
              type='text'
              className={` ${
                isEdit
                  ? 'hidden'
                  : 'border rounded-[10px] w-[402px] bg-sk-box002 px-[15px] py-[10px] text-body2-medium'
              }`}
              onChange={(e) => {
                setPortfolioInputList({
                  ...portfolioInputList,
                  description: e.target.value,
                })
                onInputChange('description', e.target.value)
              }}
              required
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPortfolioInput
