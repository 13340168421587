import { useLocation, useNavigate } from 'react-router-dom'
import { postAdminLogout } from '../apis/logout.ts'
import { useSetRecoilState } from 'recoil'

import loginState from '../states/loginState.ts'

import ElabLogo from '../assets/logos/elablogo.svg'

function SideMenuBar() {
  const location = useLocation()
  const navigate = useNavigate()
  const setLogin = useSetRecoilState(loginState)

  const logoutClick = async () => {
    const postAdminLogoutResponse = await postAdminLogout()
    if (postAdminLogoutResponse.error) {
      alert(postAdminLogoutResponse.message)
      return
    }
    // alert('로그아웃되었습니다.')
    setLogin({ isLoggedIn: false })
  }

  const menuList = [
    {
      pathname: '/main',
      content: '메인페이지',
    },
    {
      pathname: '/company',
      content: '회사소개',
    },
    {
      pathname: '/business',
      content: '비즈니스',
    },
    {
      pathname: '/portfolio',
      content: '포트폴리오',
    },
    {
      pathname: '/link',
      content: '링크',
    },
    {
      pathname: '/footerinfo',
      content: '하단정보',
    },
    // {
    //   pathname: '/',
    //   content: '로그아웃',
    // },
  ]
  return (
    <div className='w-[260px] h-screen bg-sk-box002 shadow-sk fixed'>
      {/* 로고 */}
      <div className='pt-[40px] ml-[40px]'>
        <img src={ElabLogo} alt='' />
      </div>
      <div className='mt-[24px]'>
        {menuList.map((menuItem, menuIndex) => (
          <div
            key={menuIndex}
            className={`${
              location.pathname === menuItem.pathname
                ? `text-sk-default2 bg-sk-disabled text-body2-bold`
                : `text-sk-info text-body2-medium hover:bg-sk-sub2`
            } pl-[40px] py-[14px] cursor-pointer`}
            onClick={() => navigate(menuItem.pathname)}
          >
            {menuItem.content}
          </div>
        ))}
        <div
          className={`${
            location.pathname === '/'
              ? `text-sk-default2 bg-sk-disabled text-body2-bold`
              : `text-sk-info text-body2-medium hover:bg-sk-sysRedHover hover:text-sk-default2`
          } pl-[40px] py-[14px] cursor-pointer`}
          onClick={logoutClick}
        >
          로그아웃
        </div>
      </div>
    </div>
  )
}

export default SideMenuBar
