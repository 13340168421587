import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { RecoilRoot, useRecoilState } from 'recoil'

import { getUserProfile } from './apis/profile.ts'
import Login from './views/Login.jsx'
import Main from './views/Main.jsx'
import Company from './views/Company.jsx'
import Business from './views/Business.jsx'
// import Business from './views/BusinessTest.jsx'
import Portfolio from './views/Portfolio.jsx'
import Link from './views/Link.jsx'
import FooterInfo from './views/FooterInfo.jsx'
import loginState from './states/loginState.ts'

function RequireAuth({ children, redirectTo }) {
  let locate = useLocation()
  console.log('Require Auth ' + locate.pathname)

  // const login = useRecoilValue(loginState)
  const [login, setLogin] = useRecoilState(loginState)
  console.log(login)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await getUserProfile()
      if (profileRes.error === true) {
        setLogin({ isLoggedIn: false })
      } else {
        setLogin({
          isLoggedIn: true,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === true) {
    return children
  } else if (login.isLoggedIn === false) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

function NoRequireAuth({ children, redirectTo }) {
  let locate = useLocation()
  console.log('NoRequire Auth ' + locate.pathname)

  const [login, setLogin] = useRecoilState(loginState)
  console.log(login)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await getUserProfile()
      if (profileRes.error === true) {
        setLogin({ isLoggedIn: false })
      } else {
        setLogin({
          isLoggedIn: true,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === false) {
    return children
  } else if (login.isLoggedIn === true) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

function App() {
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Navigate to='/main' />
              </RequireAuth>
            }
          />

          <Route
            path='/login'
            element={
              <NoRequireAuth redirectTo={'/main'}>
                <Login />
              </NoRequireAuth>
            }
          />

          <Route
            path='/main'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Main />
              </RequireAuth>
            }
          />

          <Route
            path='/company'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Company />
              </RequireAuth>
            }
          />

          <Route
            path='/business'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Business />
              </RequireAuth>
            }
          />

          <Route
            path='/portfolio'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Portfolio />
              </RequireAuth>
            }
          />

          <Route
            path='/link'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Link />
              </RequireAuth>
            }
          />
          <Route
            path='/footerinfo'
            element={
              <RequireAuth redirectTo={'/login'}>
                <FooterInfo />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </RecoilRoot>
  )
}

export default App
