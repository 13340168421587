import { useEffect, useState } from 'react'

import SideMenuBar from '../components/SideMenuBar.jsx'
import {
  getCompanyHistory,
  getCompanyOutline,
  postCompanyHistoryAdd,
  postCompanyHistoryDelete,
  postCompanyHistoryUpdate,
} from '../apis/company.ts'
import { postCompanyOutlineUpdate } from '../apis/company.ts'

function Company() {
  useEffect(() => {
    getCompanyOutlineApi()
    getCompanyHistoryApi()
  }, [])

  // 수정 / 완료 상태
  // const [isEdit, setIsEdit] = useState(true)
  const [isOutlineEdit, setIsOutlineEdit] = useState(true)
  const [isHistoryEdit, setIsHistoryEdit] = useState(true)

  /**********************************/
  /************ 회사 개요 ************/
  /*********************************/

  // 회사 개요 정보
  const [outlineList, setOutlineList] = useState([])
  const getCompanyOutlineApi = async () => {
    const getCompanyOutlineResponse = await getCompanyOutline()
    if (getCompanyOutlineResponse.error) {
      console.log(getCompanyOutlineResponse)
      return
    }
    console.log(getCompanyOutlineResponse)
    setOutlineList(getCompanyOutlineResponse.data)
  }
  // 회사 개요 수정
  const updateOutline = async (data) => {
    for (const item of data) {
      const updatedItems = {
        idx: item.idx,
        title: item.title,
        text: item.text,
        order: item.order,
      }

      const postCompanyOutlineUpdateResponse = await postCompanyOutlineUpdate(updatedItems)
      if (postCompanyOutlineUpdateResponse.error) {
        console.log(postCompanyOutlineUpdateResponse)
        alert(postCompanyOutlineUpdateResponse.message)
        return
      } else {
        console.log(postCompanyOutlineUpdateResponse)
      }
    }
  }

  const handleOutlineSubmit = async (e) => {
    e.preventDefault()
    await updateOutline(outlineList)
    await getCompanyOutlineApi()
    setIsOutlineEdit((prev) => !prev)
  }

  /**********************************/
  /************ 회사 연혁 ************/
  /*********************************/

  // 회사 연혁 정보
  const [historyList, setHistoryList] = useState([])
  const getCompanyHistoryApi = async () => {
    const getCompanyHistoryResponse = await getCompanyHistory()
    if (getCompanyHistoryResponse.error) {
      console.log(getCompanyHistoryResponse)
      return
    }
    console.log(getCompanyHistoryResponse)
    setHistoryList(getCompanyHistoryResponse.data)
  }
  // 회사 연혁 추가
  const [newHistory, setNewHistory] = useState([])
  const addHistoryInput = () => {
    if (historyList.length < 6) {
      setNewHistory((prev) => [
        ...prev,
        {
          year: '',
          content: '',
        },
      ])
    }
  }
  const addHistory = async (data) => {
    const postCompanyHistoryAddResponse = await postCompanyHistoryAdd(data)
    if (postCompanyHistoryAddResponse.error) {
      console.log(postCompanyHistoryAddResponse.error)
      return
    } else {
      console.log(postCompanyHistoryAddResponse)
      setHistoryList((prev) => {
        const updatedList = [...prev, data]
        return updatedList.sort((a, b) => a.year - b.year) // year 값으로 정렬
      })
    }
  }
  const deleteHistoryInput = (index) => {
    const updatedItems = newHistory.filter((_, itemIndex) => itemIndex !== index)
    setNewHistory(updatedItems)
  }
  // 회사 연혁 수정
  const updateHistory = async (data) => {
    for (const item of data) {
      const updatedHistoryItems = {
        idx: item.idx,
        year: item.year,
        content: item.content,
      }
      const postCompanyHistoryUpdateResponse = await postCompanyHistoryUpdate(updatedHistoryItems)
      if (postCompanyHistoryUpdateResponse.error) {
        console.log(postCompanyHistoryUpdateResponse.error)
        alert(postCompanyHistoryUpdateResponse.message)
        return
      } else {
        console.log(postCompanyHistoryUpdateResponse)
      }
    }
  }

  // 회사 연혁 삭제
  const deleteHistory = async (data, index) => {
    const postCompanyHistoryDeleteResponse = await postCompanyHistoryDelete({
      idx: data[index].idx,
    })
    if (postCompanyHistoryDeleteResponse.error) {
      return
    } else {
      const deletedItems = data.filter((item, itemIndex) => item.idx !== data[index].idx)
      setHistoryList(deletedItems)
      console.log(postCompanyHistoryDeleteResponse)
    }
  }

  const handleInputChange = (data, setData, index, field, e) => {
    const updatedData = [...data]
    updatedData[index][field] = e.target.value.split('\n').join('|')
    setData(updatedData)
  }

  const handleHistorySubmit = async (e) => {
    e.preventDefault()
    for (const item of newHistory) {
      await addHistory(item)
    }
    setNewHistory([])
    await updateHistory(historyList)
    await getCompanyHistoryApi()
    setIsHistoryEdit((prev) => !prev)
  }

  return (
    <div className='flex h-screen bg-sk-box004'>
      <SideMenuBar />
      <div className='w-full overflow-auto'>
        <div className='flex flex-col mr-[90px] my-[60px] gap-[30px] ml-[350px]'>
          <div className='flex gap-[30px] items-center p-[20px] bg-sk-box002 rounded-[10px] w-full shadow-sk'>
            <p className='text-h3-bold'>회사 소개</p>
          </div>
          {/* 회사 개요 */}
          <div className='bg-sk-box002 p-[30px] flex flex-col gap-[30px] rounded-[10px] shadow-sk w-full'>
            <form onSubmit={handleOutlineSubmit}>
              <div className='flex flex-col gap-[12px]'>
                <div className='flex gap-[20px] items-center'>
                  <p className='text-h3-semibold text-sk-default'>회사 개요</p>
                  <div className='flex gap-[12px] items-center'>
                    <button
                      type='button'
                      onClick={() => {
                        setIsOutlineEdit((prev) => !prev)
                      }}
                      className={`${isOutlineEdit ? 'btn-primary_small' : 'hidden'}`}
                    >
                      수정
                    </button>

                    <button
                      type='submit'
                      className={`${isOutlineEdit ? 'hidden' : 'btn-primary_small'}`}
                    >
                      완료
                    </button>
                  </div>
                </div>

                <div className='flex flex-col'>
                  {outlineList.map((infoItem, index) => (
                    <div key={index} className='flex flex-col '>
                      <div className='flex gap-[12px] items-start px-[8px] pt-[10px] pb-[4px]'>
                        <p className='text-body2-bold  min-w-[30px] text-sk-info shrink-0'>제목</p>
                        <p className={`${isOutlineEdit ? 'text-body2-medium' : 'hidden'}`}>
                          {infoItem.title}
                        </p>
                        <input
                          type='text'
                          className={` ${
                            isOutlineEdit
                              ? 'hidden'
                              : 'border rounded-[10px] w-[402px] bg-sk-box002 px-[15px] py-[10px] text-body2-medium'
                          }`}
                          value={infoItem.title}
                          required
                          onChange={(e) =>
                            handleInputChange(outlineList, setOutlineList, index, 'title', e)
                          }
                        />
                      </div>
                      <div className='flex gap-[12px] items-start px-[8px] pt-[4px] pb-[10px] border-b-[1px] border-solid border-sk-border'>
                        <p className='text-body2-bold text-sk-info min-w-[30px] shrink-0'>내용</p>
                        <div className={`${isOutlineEdit ? 'text-body2-medium' : 'hidden'}`}>
                          {infoItem.text.split('|').map((line, lineIndex) => (
                            <p key={lineIndex}>{line}</p>
                          ))}
                        </div>
                        <textarea
                          className={` ${
                            isOutlineEdit
                              ? 'hidden'
                              : 'text-body2-medium border border-solid border-sk-border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                          }`}
                          value={infoItem.text.split('|').join('\n')}
                          rows={4}
                          required
                          onChange={(e) => {
                            handleInputChange(outlineList, setOutlineList, index, 'text', e)
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </div>
          {/* 연혁 */}
          <div className='bg-sk-box002 p-[30px] flex flex-col gap-[30px] rounded-[10px] shadow-sk w-full'>
            <form onSubmit={handleHistorySubmit}>
              <div className='flex flex-col gap-[12px]'>
                <div className='flex gap-[20px] items-center'>
                  <p className='text-h3-semibold text-sk-default'>연혁</p>
                  <div className='flex gap-[12px] items-center'>
                    <button
                      type='button'
                      onClick={() => {
                        setIsHistoryEdit((prev) => !prev)
                      }}
                      className={`${isHistoryEdit ? 'btn-primary_small' : 'hidden'}`}
                    >
                      수정
                    </button>
                    {isHistoryEdit ? null : (
                      <button
                        type='button'
                        onClick={addHistoryInput}
                        className={`btn-primary_small ${
                          historyList.length + newHistory.length >= 6 ? 'hidden' : ''
                        }`}
                      >
                        추가
                      </button>
                    )}
                    <button
                      type='submit'
                      className={`${isHistoryEdit ? 'hidden' : 'btn-primary_small'}`}
                    >
                      완료
                    </button>
                  </div>
                </div>
                <div className='flex flex-col'>
                  {historyList.map((historyItem, historyIndex) => (
                    <div
                      key={historyIndex}
                      className='flex gap-[12px] w-full items-start border-b-[1px] border-solid border-sk-border'
                    >
                      <div className='flex flex-col w-full'>
                        <div className='flex gap-[12px] items-center px-[8px] pt-[10px] pb-[4px]'>
                          <p className='text-body2-bold text-sk-info min-w-[30px] shrink-0'>연도</p>
                          <p className={`${isHistoryEdit ? 'text-body2-medium' : 'hidden'}`}>
                            {historyItem.year}
                          </p>
                          <input
                            type='text'
                            className={` ${
                              isHistoryEdit
                                ? 'hidden'
                                : 'border border-solid border-sk-border rounded-[10px] w-[402px] bg-sk-box002 px-[15px] py-[10px] text-body2-medium'
                            }`}
                            value={historyItem.year}
                            required
                            onChange={(e) =>
                              handleInputChange(
                                historyList,
                                setHistoryList,
                                historyIndex,
                                'year',
                                e
                              )
                            }
                          />
                        </div>
                        <div className='flex gap-[12px] items-start px-[8px] pt-[4px] pb-[10px]'>
                          <p className='text-body2-bold text-sk-info min-w-[30px] shrink-0'>내용</p>
                          <div className={`${isHistoryEdit ? 'text-body2-medium' : 'hidden'}`}>
                            {historyItem.content.split('|').map((line, lineIndex) => (
                              <p key={lineIndex}>{line}</p>
                            ))}
                          </div>
                          <textarea
                            value={historyItem.content.split('|').join('\n')}
                            className={` ${
                              isHistoryEdit
                                ? 'hidden'
                                : 'text-body2-medium border border-solid border-sk-border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                            }`}
                            rows={4}
                            required
                            onChange={(e) => {
                              handleInputChange(
                                historyList,
                                setHistoryList,
                                historyIndex,
                                'content',
                                e
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='p-[10px]'>
                        <button
                          type='button'
                          onClick={() => deleteHistory(historyList, historyIndex)}
                          className={`${isHistoryEdit ? 'hidden' : 'btn-primary_mini'}`}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                  {newHistory.map((historyItem, historyIndex) => (
                    <div
                      key={historyIndex}
                      className='flex gap-[12px] w-full items-start border-b-[1px] border-solid border-sk-border'
                    >
                      <div className='flex flex-col w-full'>
                        <div className='flex gap-[12px] items-center px-[8px] pt-[10px] pb-[4px]'>
                          <p className='text-body2-bold text-sk-info min-w-[30px] shrink-0'>연도</p>
                          <p className={`${isHistoryEdit ? 'text-body2-medium' : 'hidden'}`}>
                            {historyItem.year}
                          </p>
                          <input
                            type='text'
                            className={` ${
                              isHistoryEdit
                                ? 'hidden'
                                : 'border border-solid border-sk-border rounded-[10px] w-[402px] bg-sk-box002 px-[15px] py-[10px] text-body2-medium'
                            }`}
                            value={historyItem.year}
                            required
                            onChange={(e) =>
                              handleInputChange(newHistory, setNewHistory, historyIndex, 'year', e)
                            }
                          />
                        </div>
                        <div className='flex gap-[12px] items-center px-[8px] pt-[4px] pb-[10px]'>
                          <p className='text-body2-bold text-sk-info min-w-[30px] shrink-0'>내용</p>
                          <p className={`${isHistoryEdit ? 'text-body2-medium' : 'hidden'}`}>
                            {historyItem.content.split('|').map((line, lineIndex) => (
                              <p key={lineIndex}>{line}</p>
                            ))}
                          </p>
                          <textarea
                            value={historyItem.content.split('|').join('\n')}
                            className={` ${
                              isHistoryEdit
                                ? 'hidden'
                                : 'text-body2-medium border border-solid border-sk-border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                            }`}
                            rows={4}
                            required
                            onChange={(e) => {
                              handleInputChange(
                                newHistory,
                                setNewHistory,
                                historyIndex,
                                'content',
                                e
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='p-[10px]'>
                        <button
                          type='button'
                          onClick={() => deleteHistoryInput(historyIndex)}
                          className={`${isHistoryEdit ? 'hidden' : 'btn-primary_mini'}`}
                        >
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Company
