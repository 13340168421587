import { useEffect, useState } from 'react'
import SideMenuBar from '../components/SideMenuBar'
import { getFooterText } from '../apis/footer.ts'
import { postFooterTextUpdate } from '../apis/footer.ts'

function FooterInfo() {
  useEffect(() => {
    getFooterTextApi()
  }, [])

  // 수정 / 완료 상태
  const [isEdit, setIsEdit] = useState(true)

  // 하단 푸터 내용 정보
  const [footerText, setFooterText] = useState([])
  const getFooterTextApi = async () => {
    const getFooterTextResponse = await getFooterText()

    if (getFooterTextResponse.error) {
      console.log(getFooterTextResponse)
      return
    }
    console.log(getFooterTextResponse)
    setFooterText(getFooterTextResponse.data)
  }

  // 하단 푸터 내용 수정
  const updateFooterText = async (data) => {
    const postFooterTextUpdateResponse = await postFooterTextUpdate(data)
    if (postFooterTextUpdateResponse.error) {
      console.log(postFooterTextUpdateResponse.error)
      alert(postFooterTextUpdateResponse.message)
      return
    }
    console.log(postFooterTextUpdateResponse)
  }

  return (
    <div className='flex h-screen bg-sk-box004'>
      <SideMenuBar />
      <div className='w-full overflow-auto'>
        <div className='flex flex-col mr-[90px] my-[60px] gap-[30px] ml-[350px]'>
          <form
            onSubmit={async (e) => {
              e.preventDefault()

              await updateFooterText(footerText)
              await getFooterTextApi()

              setIsEdit((prev) => !prev)
            }}
          >
            <div className='flex flex-col gap-[30px]'>
              <div className='flex gap-[30px] items-center p-[20px] bg-sk-box002 rounded-[10px] w-full shadow-sk'>
                <p className='text-h3-bold'>하단 정보</p>
                <button
                  type='button'
                  onClick={() => {
                    setIsEdit((prev) => !prev)
                  }}
                  className={`${isEdit ? 'btn-primary_small' : 'hidden'}`}
                >
                  수정
                </button>
                <button
                  // onClick={(e) => onClickEditOrSubmit(e)}
                  type='submit'
                  className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
                >
                  완료
                </button>
              </div>
              <div className='p-[30px] flex flex-col rounded-[10px] bg-sk-box002 shadow-sk'>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>대표이사</p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{footerText.ceo}</p>
                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px] items-end'
                    }`}
                    value={footerText.ceo}
                    required
                    onChange={(e) => setFooterText({ ...footerText, ceo: e.target.value })}
                  />
                </div>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>정보책임자</p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{footerText.cpo}</p>

                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                    }`}
                    value={footerText.cpo}
                    required
                    onChange={(e) => setFooterText({ ...footerText, cpo: e.target.value })}
                  />
                </div>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>
                    사업자등록번호
                  </p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                    {footerText.regNumber}
                  </p>
                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                    }`}
                    value={footerText.regNumber}
                    required
                    onChange={(e) => setFooterText({ ...footerText, regNumber: e.target.value })}
                  />
                </div>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>대표번호</p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                    {footerText.telNumber}
                  </p>
                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                    }`}
                    value={footerText.telNumber}
                    required
                    onChange={(e) => setFooterText({ ...footerText, telNumber: e.target.value })}
                  />
                </div>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>본사</p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                    {footerText.headOffice}
                  </p>
                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px] items-end'
                    }`}
                    value={footerText.headOffice}
                    required
                    onChange={(e) => setFooterText({ ...footerText, headOffice: e.target.value })}
                  />
                </div>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>팩스번호</p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                    {footerText.faxNumber}
                  </p>
                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                    }`}
                    value={footerText.faxNumber}
                    required
                    onChange={(e) => setFooterText({ ...footerText, faxNumber: e.target.value })}
                  />
                </div>
                <div className='flex gap-[12px] items-center px-[8px] py-[10px] '>
                  <p className='text-body2-bold text-sk-info min-w-[100px] shrink-0'>연구소</p>
                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                    {footerText.researchInstitute}
                  </p>
                  <input
                    type='text'
                    className={`border rounded-[10px] ${
                      isEdit
                        ? 'hidden'
                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                    }`}
                    value={footerText.researchInstitute}
                    required
                    onChange={(e) =>
                      setFooterText({ ...footerText, researchInstitute: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FooterInfo
