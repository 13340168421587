import { CommonResponse, get, post } from './common.ts'

/**
 * (01)
 * 회사 소개 페이지 회사 개요 정보
 */

// Get company/outline
interface GetCompanyOutlineResponse {
  idx: number
  title: string
  text: string
  order: number
}
export const getCompanyOutline = async (): Promise<CommonResponse<GetCompanyOutlineResponse>> => {
  return await get('/company/outline')
}

/**
 * (02)
 * 회사 소개 페이지 회사 개요 추가
 */

// Get company/outline/add

/**
 * (03)
 * 회사 소개 페이지 회사 개요 수정
 */

// Get company/outline/update
interface PostCompanyOutlineUpdateRequest {
  idx: number
  title: string
  text: string
  order: number
}

export const postCompanyOutlineUpdate = async (
  body: PostCompanyOutlineUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/company/outline/update', body)
}

/**
 * (04)
 * 회사 소개 페이지 회사 개요 삭제
 */

// Get company/outline/delete

/**
 *(05)
 *회사 소개 페이지 회사 연혁 정보
 */

// GET /company/history
interface GetCompanyHistoryResponse {
  idx: number
  year: string
  content: string
}

export const getCompanyHistory = async (): Promise<CommonResponse<GetCompanyHistoryResponse>> => {
  return await get('/company/history')
}

/**
 *(06)
 *회사 소개 페이지 회사 연혁 추가
 */

// POST /company/historyadd
interface PostCompanyHistoryAddRequest {
  idx: number
  year: string
  content: string
}

export const postCompanyHistoryAdd = async (
  body: PostCompanyHistoryAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/company/history/add', body)
}

/**
 *(07)
 *회사 소개 페이지 회사 연혁 수정
 */

// POST /company/history/update
interface PostCompanyHistoryUpdateRequest {
  idx: number
  year: string
  content: string
}

export const postCompanyHistoryUpdate = async (
  body: PostCompanyHistoryUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/company/history/update', body)
}
/**
 *(08)
 *회사 소개 페이지 회사 연혁 삭제
 */

// POST /company/history/delete
interface PostCompanyHistoryDeleteRequest {
  idx: number
}

export const postCompanyHistoryDelete = async (
  body: PostCompanyHistoryDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/company/history/delete', body)
}
