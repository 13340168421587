import { CommonResponse, get, post } from './common.ts'

/**
 * (01)
 * 하단 푸터 내용 정보
 */

// Get /footer/text
interface GetFooterTextResponse {
  ceo: string
  cpo: string
  regNumber: string
  telNumber: string
  headOffice: string
  faxNumber: string
  researchInstitute: string
}
export const getFooterText = async (): Promise<CommonResponse<GetFooterTextResponse>> => {
  return await get('/footer/text')
}

/**
 * (02)
 * 하단 푸터 내용 수정
 */

// Get /footer/text/update
interface PostFooterTextUpdateRequest {
  ceo: string
  cpo: string
  regNumber: string
  telNumber: string
  headOffice: string
  faxNumber: string
  researchInstitute: string
}

export const postFooterTextUpdate = async (
  body: PostFooterTextUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/footer/text/update', body)
}
