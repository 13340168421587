import { CommonResponse, get, post } from './common.ts'

/**
 * (01)
 * 메인페이지 배경사진 정보
 */

// Get /main-page/background
interface GetMainPageBackgroundResponse {
  title: string
  subTitle: string
  text: string
  imgUrl: string
  idx: number
}

export const getMainPageBackground = async (): Promise<
  CommonResponse<GetMainPageBackgroundResponse>
> => {
  return await get('/main-page/background')
}

/**
 *(02)
 *메인페이지 배경사진 추가
 */

// POST /main-page/background/add
interface PostMainPageBackgroundAddRequest {
  title: string
  subTitle: string
  text: string
  imgUrl: string
  idx: number
}

export const postMainPageBackgroundAdd = async (
  body: PostMainPageBackgroundAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/background/add', body)
}

/**
 *(03)
 *메인페이지 배경사진 수정
 */

// POST /main-page/background/update
interface PostMainPageBackgroundUpdateRequest {
  title: string
  subTitle: string
  text: string
  imgUrl: string
  idx: number
}

export const postMainPageBackgroundUpdate = async (
  body: PostMainPageBackgroundUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/background/update', body)
}

/**
 *(04)
 *메인페이지 배너 사진 리스트 정보
 */

// GET /main-page/banner
interface GetMainPageBannerResponse {
  idx: number
  imgUrl: string
  order: number
}

export const getMainPageBanner = async (): Promise<CommonResponse<GetMainPageBannerResponse>> => {
  return await get('/main-page/banner')
}

/**
 *(05)
 *메인페이지 배너 사진 추가
 */

// POST /main-page/banner/add
interface PostMainPageBannerAddRequest {
  imgUrl: string
  order: number
}

export const postMainPageBannerAdd = async (
  body: PostMainPageBannerAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/banner/add', body)
}

/**
 *(06)
 *메인페이지 배너 사진 수정
 */

// POST /main-page/banner/update
interface PostMainPageBannerUpdateRequest {
  imgUrl: string
  idx: number
  order: number
}

export const postMainPageBannerUpdate = async (
  body: PostMainPageBannerUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/banner/update', body)
}

/**
 *(07)
 *메인페이지 배너 사진 삭제
 */

// POST /main-page/banner/delete
interface PostMainPageBannerDeleteRequest {
  idx: number
}

export const postMainPageBannerDelete = async (
  body: PostMainPageBannerDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/banner/delete', body)
}

/**
 * (08)
 * 메인페이지 아이템 정보
 */

// Get /main-page/item
interface GetMainPageItemResponse {
  idx: number
  type: number
  title: string
  subTitle: string
  imgUrl: string
  order: number
}

export const getMainPageItem = async (): Promise<CommonResponse<GetMainPageItemResponse>> => {
  return await get('/main-page/item')
}

/**
 *(09)
 *메인페이지 아이템 추가
 */

// POST /main-page/item/add
interface PostMainPageItemAddRequest {
  imgUrl: string
  title: string
  subTitle: string
  type: number
  order: number
}

export const postMainPageItemAdd = async (
  body: PostMainPageItemAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/item/add', body)
}

/**
 *(06)
 *메인페이지 아이템 수정
 */

// POST /main-page/item/update
interface PostMainPageItemUpdateRequest {
  imgUrl: string
  idx: number
  title: string
  subTitle: string
  type: number
  order: number
}

export const postMainPageItemUpdate = async (
  body: PostMainPageItemUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/item/update', body)
}

/**
 *(07)
 *메인페이지 아이템 삭제
 */

// POST /main-page/item/delete
interface PostMainPageItemDeleteRequest {
  idx: number
}

export const postMainPageItemDelete = async (
  body: PostMainPageItemDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/main-page/item/delete', body)
}
