import { postUtilFileUpload } from '../apis/image.ts'

function ImgPreview({ isEdit, itemImg, onImageChange, disabled, imgSize, onDelete }) {
  const imageUpload = async (file) => {
    const postUtilFileUploadResponse = await postUtilFileUpload({ image: file })
    if (postUtilFileUploadResponse.error) {
      console.log(postUtilFileUploadResponse.error)
      return
    }
    console.log(postUtilFileUploadResponse)
    const uploadedImgUrl = postUtilFileUploadResponse.data
    onImageChange(uploadedImgUrl)
  }

  return (
    <div className='flex items-center'>
      <div className='flex flex-col items-center gap-[12px]'>
        {/* 이미지 미리보기 */}
        <div className='flex'>
          {itemImg && (
            <div className='flex flex-col items-center'>
              <img src={itemImg} alt='preview-img' className={imgSize} />
            </div>
          )}
        </div>

        {!isEdit && (
          <div className='flex gap-[12px] items-center'>
            <label className='btn-primary_small min-w-[85px]'>
              파일업로드
              <input
                type='file'
                accept='image/*'
                onChange={(e) => {
                  if (!disabled && e.target.files[0]) {
                    imageUpload(e.target.files[0])
                  }
                }}
                className='hidden'
                disabled={disabled}
              />
            </label>
            {onDelete && (
              <button
                type='button'
                onClick={onDelete}
                className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
              >
                X
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default ImgPreview
