import { CommonResponse, get, post } from './common.ts'

/**
 * (01)
 * 포트폴리오 페이지 아이템 정보
 */

// Get /portfolio/item
interface GetPortfolioItemResponse {
  idx: number
  title: string
  description: string
  imgUrl: string
  order: number
}

export const getPortfolioItem = async (): Promise<CommonResponse<GetPortfolioItemResponse>> => {
  return await get('/portfolio/item')
}

/**
 *(02)
 *포트폴리오 페이지 아이템 추가
 */

// POST /portfolio/item/add
interface PostPortfolioItemAddRequest {
  title: string
  description: string
  order: number
  imgUrl: string
}

export const postPortfolioItemAdd = async (
  body: PostPortfolioItemAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/portfolio/item/add', body)
}

/**
 *(03)
 *포트폴리오 페이지 아이템 수정
 */

// POST /portfolio/item/update
interface PostPortfolioItemUpdateRequest {
  idx: number
  title: string
  description: string
  imgUrl: string
  order: number
}

export const postPortfolioItemUpdate = async (
  body: PostPortfolioItemUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/portfolio/item/update', body)
}

/**
 *(04)
 *포트폴리오 페이지 아이템 삭제
 */

// POST /portfolio/item/delete
interface PostPortfolioItemDeleteRequest {
  idx: number
}

export const postPortfolioItemDelete = async (
  body: PostPortfolioItemDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/portfolio/item/delete', body)
}
