import { CommonResponse, get, post } from './common.ts'

/**
 * (01)
 * 링크 정보
 */

// Get /link
interface GetLinkResponse {
  idx: string
  naverBlog: string
  appStore: string
  tistory: string
  instagram: string
  youtube: string
  facebook: string
  kakaoTalk: string
}
export const getLink = async (): Promise<CommonResponse<GetLinkResponse>> => {
  return await get('/link')
}

/**
 * (02)
 * 링크 수정
 */

// Get /link/update
interface PostLinkUpdateRequest {
  idx: string
  naverBlog: string
  appStore: string
  tistory: string
  instagram: string
  youtube: string
  faceBook: string
  kakaoTalk: string
}

export const postLinkUpdate = async (
  body: PostLinkUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/link/update', body)
}

/**
 * (03)
 * 링크 삭제
 */

// Get /link/delete
interface PostLinkDeleteRequest {
  idx: string
}

export const postLinkDelete = async (
  body: PostLinkDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/link/delete', body)
}
