import { useEffect, useState } from 'react'

import SideMenuBar from '../components/SideMenuBar'
import { getLink, postLinkUpdate } from '../apis/link.ts'

function Link() {
  useEffect(() => {
    getLinkApi()
  }, [])

  // 수정
  const [isEdit, setIsEdit] = useState(true)

  // 링크 정보
  const [linkList, setLinkList] = useState({})

  const getLinkApi = async () => {
    const getLinkResponse = await getLink()
    if (getLinkResponse.error) {
      console.log(getLinkResponse)
      return
    }
    console.log(getLinkResponse)
    setLinkList(getLinkResponse.data)
  }

  // 링크 정보 수정
  const updateLink = async (data) => {
    console.log(data)
    const postLinkUpdateResponse = await postLinkUpdate(data)
    if (postLinkUpdateResponse.error) {
      console.log(postLinkUpdateResponse.error)
      alert(postLinkUpdateResponse.message)
      return
    }
    console.log(postLinkUpdateResponse)
  }

  const onClickEditOrSubmit = async (e) => {
    e.preventDefault()
    await updateLink(linkList)
    await getLinkApi()
    setIsEdit((prev) => !prev)
  }

  return (
    <div className='flex h-screen bg-sk-box004'>
      <SideMenuBar />
      <div className='w-full overflow-auto'>
        <div className='flex flex-col mr-[90px] my-[60px] gap-[30px] ml-[350px]'>
          <div className='flex gap-[30px] items-center p-[20px] bg-sk-box002 rounded-[10px] w-full shadow-sk'>
            <p className='text-h3-bold'>링크</p>
            <button
              type='button'
              onClick={() => {
                setIsEdit((prev) => !prev)
              }}
              className={`${isEdit ? 'btn-primary_small' : 'hidden'}`}
            >
              수정
            </button>
            <button
              type='submit'
              onClick={(e) => onClickEditOrSubmit(e)}
              className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
            >
              완료
            </button>
          </div>
          <div className='p-[30px] flex flex-col rounded-[10px] bg-sk-box002 shadow-sk'>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>네이버 블로그</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.naverBlog}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.naverBlog}
                onChange={(e) => setLinkList({ ...linkList, naverBlog: e.target.value })}
              />
            </div>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>App Store</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.appStore}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.appStore}
                onChange={(e) => setLinkList({ ...linkList, appStore: e.target.value })}
              />
            </div>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>티스토리</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.tistory}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.tistory}
                onChange={(e) => setLinkList({ ...linkList, tistory: e.target.value })}
              />
            </div>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>인스타그램</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.instagram}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.instagram}
                onChange={(e) => setLinkList({ ...linkList, instagram: e.target.value })}
              />
            </div>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>유튜브</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.youtube}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.youtube}
                onChange={(e) => setLinkList({ ...linkList, youtube: e.target.value })}
              />
            </div>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px] border-b-[1px] border-solid border-sk-border'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>페이스북</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.faceBook}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.faceBook}
                onChange={(e) => setLinkList({ ...linkList, faceBook: e.target.value })}
              />
            </div>
            <div className='flex gap-[12px] items-center px-[8px] py-[10px]'>
              <p className='text-body2-bold text-sk-info min-w-[90px] shrink-0'>카카오톡</p>
              <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{linkList.kakaoTalk}</p>

              <input
                type='text'
                className={`border rounded-[10px] ${
                  isEdit
                    ? 'hidden'
                    : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                }`}
                value={linkList.kakaoTalk}
                onChange={(e) => setLinkList({ ...linkList, kakaoTalk: e.target.value })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Link
