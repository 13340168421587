import { atom } from 'recoil'

const loginState = atom({
  key: 'loginState',
  default: {
    isLoggedIn: null,
  },
})

export default loginState
