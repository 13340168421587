import { useState } from 'react'

import { getMainPageItem, postMainPageItemAdd } from '../apis/page.ts'

import checked from '../assets/logos/checkedbox.svg'
import check from '../assets/logos/checkbox.svg'

import ImgPreview from './ImgPreview'

function ModalAddPartners({ setModalOpen, order, setItemList }) {
  const closeModal = () => {
    setModalOpen(false)
  }

  const [isChecked, setIsChecked] = useState(false)

  const [partnersImgList, setPartnersImgList] = useState({})

  const getMainPageItemApi = async () => {
    const getMainPageItemResponse = await getMainPageItem()

    if (getMainPageItemResponse.error) {
      console.log(getMainPageItemResponse)
      return
    }
    console.log(getMainPageItemResponse)
    setItemList(getMainPageItemResponse.data)
  }

  //   메인페이지 아이템 추가

  const addItem = async (data) => {
    const postMainPageItemAddResponse = await postMainPageItemAdd(data)

    if (postMainPageItemAddResponse.error) {
      console.log(postMainPageItemAddResponse.error)
      return
    }
    console.log(postMainPageItemAddResponse)
  }

  const updatePartnersImg = (imgSrc) => {
    setPartnersImgList({
      ...partnersImgList,
      imgUrl: imgSrc,
      type: isChecked ? 2 : 1,
      order: order,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!partnersImgList.imgUrl) {
      alert('이미지를 업로드해주세요')
      return
    }
    if (partnersImgList.type === 1) {
      if (!partnersImgList.title) {
        alert('제목을 입력해주세요')
        return
      }
      if (!partnersImgList.subTitle) {
        alert('부제목을 입력해주세요')
        return
      }
    }
    await addItem(partnersImgList)
    await getMainPageItemApi()

    closeModal()
  }

  return (
    <div className='modal'>
      <div className='z-[999] w-full h-full bg-sk-default bg-opacity-50 fixed top-0 left-0 flex justify-center items-center overflow-auto'>
        <div className='relative max-h-[80vh]'>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col w-[720px] justify-center items-center gap-[50px] p-[70px] bg-sk-box004 rounded-[10px]'>
              <div className='flex items-center gap-[30px] w-full'>
                <button
                  type='button'
                  onClick={() => {
                    setIsChecked((prev) => !prev)
                    setPartnersImgList((prev) => ({
                      ...prev,
                      imgUrl: '',
                      title: '',
                      subTitle: '',
                    }))
                  }}
                >
                  <img src={isChecked ? check : checked} alt='' className='w-[30px] h-[30px]' />
                </button>
                <div className='bg-sk-box002 p-[30px] flex flex-col gap-[20px] rounded-[10px] shadow-sk w-full'>
                  {/* Type 1 */}
                  <p className='text-body2-bold'>Type 1</p>
                  <div className='flex flex-col gap-[8px]'>
                    <ImgPreview
                      label={`partnersType1`}
                      itemImg={isChecked ? null : partnersImgList.imgUrl}
                      onImageChange={(imgUrl) => updatePartnersImg(imgUrl)}
                      disabled={isChecked}
                      imgSize={`w-[150px] h-[100px]`}
                    />
                    <div className='flex gap-[12px] items-center'>
                      <p className='text-body2-bold min-w-[45px]'>제목</p>
                      <input
                        type='text'
                        value={partnersImgList.title}
                        className='border text-body2-medium w-full rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                        onChange={(e) =>
                          setPartnersImgList({
                            ...partnersImgList,
                            title: e.target.value,
                          })
                        }
                        disabled={isChecked}
                        required
                      />
                    </div>
                    <div className='flex gap-[12px] items-start'>
                      <p className='text-body2-bold min-w-[45px]'>부제목</p>
                      <textarea
                        type='text'
                        value={partnersImgList.subTitle}
                        rows={4}
                        className='text-body2-medium border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                        onChange={(e) =>
                          setPartnersImgList({
                            ...partnersImgList,
                            subTitle: e.target.value,
                          })
                        }
                        disabled={isChecked}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Type 2 */}
              <div className='flex items-center gap-[30px] w-full'>
                <button
                  type='button'
                  onClick={() => {
                    setIsChecked((prev) => !prev)
                    setPartnersImgList((prev) => ({
                      ...prev,
                      imgUrl: '',
                      title: '',
                      subTitle: '',
                    }))
                  }}
                >
                  <img src={!isChecked ? check : checked} alt='' className='w-[30px] h-[30px]' />
                </button>
                <div className='bg-sk-box002 p-[30px] flex flex-col gap-[20px] rounded-[10px] shadow-sk w-full'>
                  <p className='text-body2-bold'>Type 2</p>
                  <ImgPreview
                    label={`partnersType2`}
                    itemImg={!isChecked ? null : partnersImgList.imgUrl}
                    onImageChange={(imgUrl) => {
                      updatePartnersImg(imgUrl)
                    }}
                    disabled={!isChecked}
                    imgSize={`w-[150px] h-[100px]`}
                  />
                </div>
              </div>
              <button onClick={handleSubmit} className='btn-primary_large'>
                완료
              </button>
            </div>
          </form>
          <button
            type='button'
            onClick={closeModal}
            className='btn-primary_small absolute top-[30px] right-[30px]'
          >
            X
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalAddPartners
