import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { postAdminLogin } from '../apis/login.ts'

import loginState from '../states/loginState.ts'
import Logo from '../assets/logos/logo.svg'

function Login() {
  const { register, handleSubmit } = useForm()
  const setLogin = useSetRecoilState(loginState)

  const onSubmit = async (data) => {
    const postAdminLoginResponse = await postAdminLogin({
      id: data.id,
      password: data.pw,
    })
    if (postAdminLoginResponse.error) {
      alert(postAdminLoginResponse.message)
      return
    }
    // alert('로그인되었습니다.')
    // navigate('/main')
    // reset()
    setLogin({ isLoggedIn: true })
    // reset()
  }

  return (
    <div className='flex items-center justify-center w-full h-screen bg-sk-box004'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-[50px]'>
          {/* 로고 */}
          <div className='flex flex-col justify-center items-center gap-[10px]'>
            <img className='' src={Logo} alt='' />
            <p className='text-sk-default text-title-36'>ELAB</p>
          </div>
          <div className='flex flex-col gap-[20px]'>
            {/* 아이디 */}
            <div className='flex flex-col gap-[12px]'>
              <p className='text-sk-default text-body2-bold pl-[10px]'>아이디</p>
              <input
                {...register('id')}
                type='text'
                className='text-sk-disabled text-body2-medium w-[402px] h-[60px] rounded-[10px] bg-sk-box002 pl-[20px]'
                placeholder='아이디를 입력해주세요'
                required
              />
            </div>
            {/* 비밀번호 */}
            <div className='flex flex-col gap-[12px]'>
              <p className='text-sk-default text-body2-bold pl-[10px]'>비밀번호</p>
              <input
                {...register('pw')}
                type='password'
                className='text-sk-disabled text-body2-medium w-[402px] h-[60px] rounded-[10px] bg-sk-box002 pl-[20px]'
                placeholder='비밀번호를 입력해주세요'
                required
              />
            </div>
          </div>
          <button type='submit' className='btn-primary_large'>
            로그인
          </button>
        </div>
      </form>
    </div>
  )
}

export default Login
