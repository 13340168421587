import { useEffect, useState } from 'react'
import {
  getPortfolioItem,
  postPortfolioItemAdd,
  postPortfolioItemDelete,
  postPortfolioItemUpdate,
} from '../apis/portfolio.ts'
import SideMenuBar from '../components/SideMenuBar'
import AddPortfolioInput from '../components/AddPortfolioInput.jsx'

function Portfolio() {
  useEffect(() => {
    getPortfolioItemApi()
  }, [])

  // 수정/완료 상태
  const [isEdit, setIsEdit] = useState(true)

  // 포트폴리오 아이템 정보
  const [portfolioItem, setPortfolioItem] = useState([])
  const getPortfolioItemApi = async () => {
    const getPortfolioItemResponse = await getPortfolioItem()

    if (getPortfolioItemResponse.error) {
      console.log(getPortfolioItemResponse)
      return
    }
    console.log(getPortfolioItemResponse)
    setPortfolioItem(getPortfolioItemResponse.data)
  }

  // 추가할 포트폴리오 아이템 리스트
  const [newPortfolioItems, setNewPortfolioItems] = useState([])
  const nextOrder = (data, newData) => {
    const allItems = [...data, ...newData]
    if (allItems.length === 0) return 1
    return allItems.length + 1
  }
  const addPortfolioInput = () => {
    setNewPortfolioItems((prevItems) => [
      ...prevItems,
      {
        title: '',
        description: '',
        order: nextOrder(portfolioItem, newPortfolioItems),
        imgUrl: '',
      },
    ])
  }

  const handlePortfolioInputChange = (index, field, value) => {
    const updatedItems = [...newPortfolioItems]
    updatedItems[index][field] = value
    setNewPortfolioItems(updatedItems)
  }

  // 새로운 포트폴리오 아이템 삭제
  const deletePortfolioInput = (index) => {
    const updatedItems = newPortfolioItems.filter((_, itemIndex) => itemIndex !== index)
    setNewPortfolioItems(updatedItems)
  }

  // 기존 포트폴리오 아이템 삭제
  const deletePortfolioItem = async (index) => {
    const postPortfolioItemDeleteResponse = await postPortfolioItemDelete({
      idx: portfolioItem[index].idx,
    })

    if (postPortfolioItemDeleteResponse.error) {
      console.log(postPortfolioItemDeleteResponse.error)
      return
    }
    console.log(postPortfolioItemDeleteResponse)
    const updatedItems = portfolioItem.filter((_, itemIndex) => itemIndex !== index)
    setPortfolioItem(updatedItems)
  }

  // 포트폴리오 아이템 추가
  const addPortfolioItem = async (data) => {
    const postPortfolioItemAddResponse = await postPortfolioItemAdd(data)

    if (postPortfolioItemAddResponse.error) {
      console.log(postPortfolioItemAddResponse.error)
      console.log(postPortfolioItemAddResponse.message)
      return
    }
    console.log(postPortfolioItemAddResponse)
    setPortfolioItem((prev) => [...prev, data])
  }

  const updatePortfolioItem = async (data) => {
    const postPortfolioItemUpdateResponse = await postPortfolioItemUpdate(data)
    if (postPortfolioItemUpdateResponse.error) {
      console.log(postPortfolioItemUpdateResponse.error)
      alert(postPortfolioItemUpdateResponse.message)
      return
    } else {
      // console.log(postPortfolioItemUpdateResponse)
    }
  }

  const onClickEditOrSubmit = async (e) => {
    e.preventDefault()
    for (const item of newPortfolioItems) {
      if (item.imgUrl) {
        await addPortfolioItem(item)
      }
    }
    for (const item of portfolioItem) {
      await updatePortfolioItem(item)
    }
    await getPortfolioItemApi()
    setNewPortfolioItems([])
    setIsEdit((prev) => !prev)
  }

  const handlePrevClick = (data, setData, index, field) => {
    const updatedItems = [...data]
    const changedOrder = updatedItems[index][field]
    updatedItems[index][field] = updatedItems[index - 1][field]
    updatedItems[index - 1][field] = changedOrder
    setData(updatedItems)
  }

  const handleNextClick = (data, setData, index, field) => {
    const updatedItems = [...data]
    const changedOrder = updatedItems[index][field]
    updatedItems[index][field] = updatedItems[index + 1][field]
    updatedItems[index + 1][field] = changedOrder
    setData(updatedItems)
  }

  return (
    <div className='flex h-screen bg-sk-box004'>
      <SideMenuBar />
      <div className='w-full overflow-auto'>
        <form onSubmit={onClickEditOrSubmit}>
          <div className='flex flex-col mr-[90px] my-[60px] gap-[30px] ml-[350px]'>
            <div className='flex gap-[20px] items-center p-[20px] bg-sk-box002 rounded-[10px] w-full shadow-sk'>
              <p className='text-h3-bold'>포트폴리오</p>

              <div className='flex gap-[12px]'>
                <button
                  type='button'
                  onClick={() => setIsEdit((prev) => !prev)}
                  className={`${isEdit ? 'btn-primary_small' : 'hidden'}`}
                >
                  수정
                </button>
                <button
                  type='button'
                  onClick={addPortfolioInput}
                  className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
                >
                  추가
                </button>
                <button className={`${isEdit ? 'hidden' : 'btn-primary_small'}`} type='submit'>
                  완료
                </button>
              </div>
            </div>
            <div className='grid grid-cols-3 gap-[30px] justify-between'>
              {/* 기존 포트폴리오 아이템 */}
              {portfolioItem.map((item, index) => (
                <div
                  key={index}
                  className='bg-sk-box002 p-[30px] flex flex-col gap-[20px] rounded-[10px] shadow-sk w-full'
                >
                  {!isEdit && (
                    <div className='flex justify-between'>
                      <div className='flex gap-[12px]'>
                        {item.order !== 1 && (
                          <button
                            type='button'
                            onClick={async () => {
                              handlePrevClick(portfolioItem, setPortfolioItem, index, 'order')
                              for (const item of portfolioItem) {
                                await updatePortfolioItem(item)
                              }

                              await getPortfolioItemApi()
                            }}
                            className='btn-primary_small'
                          >
                            PREV
                          </button>
                        )}
                        {item.order !== portfolioItem.length && (
                          <button
                            type='button'
                            onClick={async () => {
                              handleNextClick(portfolioItem, setPortfolioItem, index, 'order')
                              for (const item of portfolioItem) {
                                await updatePortfolioItem(item)
                              }
                              await getPortfolioItemApi()
                            }}
                            className='btn-primary_small'
                          >
                            NEXT
                          </button>
                        )}
                      </div>
                      <button
                        type='button'
                        onClick={() => deletePortfolioItem(index)}
                        className='btn-primary_small'
                      >
                        X
                      </button>
                    </div>
                  )}
                  <img src={item.imgUrl} alt='' className='w-full h-full' />
                  <div className='flex flex-col gap-[8px] items-start'>
                    <div className='flex gap-[8px] items-center'>
                      <div className='flex gap-[12px] min-w-[45px]'>
                        <p className='text-body2-bold min-w-[30px] text-sk-info shrink-0'>제목</p>
                        <p className='text-body2-medium'>{item.title}</p>
                      </div>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                      <div className='flex gap-[12px] min-w-[45px]'>
                        <p className='text-body2-bold min-w-[30px] text-sk-info shrink-0'>내용</p>
                        <p className='text-body2-medium'>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* 추가 포트폴리오 아이템 */}
              {newPortfolioItems.map((item, index) => (
                <AddPortfolioInput
                  key={index}
                  order={item.order}
                  onInputChange={(field, value) => handlePortfolioInputChange(index, field, value)}
                  onDelete={() => deletePortfolioInput(index)}
                  isEdit={isEdit}
                />
              ))}

              <div
                className={`${
                  isEdit
                    ? 'hidden'
                    : 'bg-sk-box002 p-[30px] flex flex-col gap-[20px] rounded-[10px] shadow-sk w-full items-center justify-center'
                }`}
              >
                <button
                  type='button'
                  onClick={addPortfolioInput}
                  className='text-center  text-sk-sub text-title-100'
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Portfolio
