import { useEffect, useState } from 'react'
import {
  getMainPageBackground,
  getMainPageItem,
  postMainPageBannerDelete,
  postMainPageBannerUpdate,
  postMainPageItemDelete,
  postMainPageItemUpdate,
} from '../apis/page.ts'
import { postMainPageBackgroundAdd } from '../apis/page.ts'
import { postMainPageBackgroundUpdate } from '../apis/page.ts'
import { getMainPageBanner } from '../apis/page.ts'
import { postMainPageBannerAdd } from '../apis/page.ts'

import SideMenuBar from '../components/SideMenuBar'
import ModalAddPartners from '../components/ModalAddPartners'
import ImgPreview from '../components/ImgPreview'

import upIcon from '../assets/logos/up.svg'
import downIcon from '../assets/logos/down.svg'

function Main() {
  useEffect(() => {
    getMainPageBackgroundApi()
    getMainPageBannerApi()
    getMainPageItemApi()
  }, [])

  //  수정 / 완료 상태
  const [isBgImgEdit, setIsBgImgEdit] = useState(true)
  const [isBannerImgEdit, setIsBannerImgEdit] = useState(true)
  const [isItemEdit, setIsItemEdit] = useState(true)

  /**********************************/
  /********* 메인페이지 배경사진 *********/
  /**********************************/

  //   메인페이지 배경사진 정보
  const [bgImgList, setBgImgList] = useState({})
  const getMainPageBackgroundApi = async () => {
    const getMainPageBackgroundResponse = await getMainPageBackground()

    if (getMainPageBackgroundResponse.error) {
      console.log(getMainPageBackgroundResponse)
      return
    }
    console.log(getMainPageBackgroundResponse)
    setBgImgList(getMainPageBackgroundResponse.data)
  }

  // 메인페이지 배경사진 추가 / 수정
  const submitBackground = async (data) => {
    if (data.length === 0) {
      const postMainPageBackgroundAddResponse = await postMainPageBackgroundAdd({
        title: data.title,
        subTitle: data.subTitle,
        text: data.text,
        imgUrl: data.imgUrl,
        idx: data.idx,
      })
      if (postMainPageBackgroundAddResponse.error) {
        console.log(postMainPageBackgroundAddResponse)
        return
      }
    } else {
      const postMainPageBackgroundUpdateResponse = await postMainPageBackgroundUpdate({
        title: data.title,
        subTitle: data.subTitle,
        text: data.text,
        imgUrl: data.imgUrl,
        idx: data.idx,
      })
      if (postMainPageBackgroundUpdateResponse.error) {
        console.log(postMainPageBackgroundUpdateResponse)
        alert(postMainPageBackgroundUpdateResponse.message)
        return
      }
      console.log(postMainPageBackgroundUpdateResponse)
    }
  }

  const updateBgImg = (bgImgList, imgSrc) => {
    const updatedItems = { ...bgImgList, imgUrl: imgSrc }
    setBgImgList(updatedItems)
  }

  const handleBgImgSubmit = async (e) => {
    e.preventDefault()
    await submitBackground(bgImgList)
    await getMainPageBackgroundApi()
    setIsBgImgEdit((prev) => !prev)
  }

  /**********************************/
  /******** 메인페이지 배너 사진 *********/
  /**********************************/

  //   메인페이지 배너 사진 정보
  const [bannerImgList, setBannerImgList] = useState([])
  const getMainPageBannerApi = async () => {
    const getMainPageBannerResponse = await getMainPageBanner()
    if (getMainPageBannerResponse.error) {
      console.log(getMainPageBannerResponse.error)
      return
    }
    console.log(getMainPageBannerResponse)
    setBannerImgList(getMainPageBannerResponse.data)
  }

  //   메인페이지 배너 사진 추가
  const [newBannerImgList, setNewBannerImgList] = useState([])

  const nextOrder = (data, newData) => {
    const allItems = [...data, ...newData]
    if (allItems.length === 0) return 1
    return allItems[allItems.length - 1].order + 1
  }
  const addBannerInput = () => {
    setNewBannerImgList((prev) => {
      const updatedList = [
        ...prev,
        { imgUrl: '', order: nextOrder(bannerImgList, newBannerImgList) },
      ]
      return updatedList.sort((a, b) => a.order - b.order)
    })
  }

  // 새로운 배너사진 아이템 삭제
  const deleteBannerInput = (index) => {
    const updatedItems = newBannerImgList.filter((_, itemIndex) => itemIndex !== index)
    setNewBannerImgList(updatedItems)
  }

  const addBannerImg = async (data) => {
    const postMainPageBannerAddResponse = await postMainPageBannerAdd(data)

    if (postMainPageBannerAddResponse.error) {
      console.log(postMainPageBannerAddResponse.error)
      return
    }
    console.log(postMainPageBannerAddResponse)
    setBannerImgList((prev) => {
      const updatedList = [...prev, data]
      return updatedList.sort((a, b) => a.order - b.order)
    })
  }

  const updateImg = (data, setData, index, imgSrc) => {
    const updatedImg = data.map((item, idx) => {
      if (idx === index) {
        return { ...item, imgUrl: imgSrc }
      }
      return item
    })
    setData(updatedImg)
  }

  // 메인페이지 배너 사진 수정
  const updateBanner = async (data) => {
    const postMainPageBannerUpdateResponse = await postMainPageBannerUpdate(data)
    if (postMainPageBannerUpdateResponse.error) {
      console.log(postMainPageBannerUpdateResponse.error)
      alert(postMainPageBannerUpdateResponse.message)
      return
    } else {
      console.log(postMainPageBannerUpdateResponse)
    }
  }

  // 메인페이지 배너 사진 삭제
  const deleteBannerImg = async (data, index) => {
    const postMainPageBannerDeleteResponse = await postMainPageBannerDelete({
      idx: data[index].idx,
    })

    if (postMainPageBannerDeleteResponse.error) {
      console.log(postMainPageBannerDeleteResponse.error)
      return
    } else {
      const deletedItems = data.filter((item) => item.idx !== data[index].idx)
      setBannerImgList(deletedItems)
      console.log(postMainPageBannerDeleteResponse)
    }
  }

  const handleBannerImgSubmit = async (e) => {
    e.preventDefault()
    for (const item of bannerImgList) {
      await updateBanner(item)
    }
    for (const item of newBannerImgList) {
      if (item.imgUrl) {
        await addBannerImg(item)
      }
    }
    await getMainPageBannerApi()
    setNewBannerImgList([])
    setIsBannerImgEdit((prev) => !prev)
  }

  /**********************************/
  /********* 메인페이지 아이템 **********/
  /*********************************/

  // 메인페이지 아이템 정보
  const [itemList, setItemList] = useState([])

  const getMainPageItemApi = async () => {
    const getMainPageItemResponse = await getMainPageItem()

    if (getMainPageItemResponse.error) {
      console.log(getMainPageItemResponse)
      return
    }
    console.log(getMainPageItemResponse)
    setItemList(getMainPageItemResponse.data)
  }

  //   메인페이지 아이템 추가
  const itemOrder = (data) => {
    if (data.length === 0) return 1
    else {
      return data[data.length - 1].order + 1
    }
  }
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => {
    setModalOpen(true)
  }

  //   메인페이지 아이템 수정
  const updateItem = async (data) => {
    const postMainPageItemUpdateResponse = await postMainPageItemUpdate(data)
    if (postMainPageItemUpdateResponse.error) {
      console.log(postMainPageItemUpdateResponse.error)
      alert(postMainPageItemUpdateResponse.message)
      return
    } else {
      console.log(postMainPageItemUpdateResponse)
    }
  }

  const handleInputChange = (data, setData, index, field, e) => {
    const updatedItems = [...data]
    updatedItems[index][field] = e.target.value
    setData(updatedItems)
  }

  const handleTextChange = (data, setData, index, field, e) => {
    const updatedItems = [...data]
    updatedItems[index][field] = e.target.value.split('\n').join('|')
    setData(updatedItems)
  }

  // 메인페이지 아이템 삭제
  const deleteItem = async (data, index) => {
    const postMainPageItemDeleteResponse = await postMainPageItemDelete({
      idx: data[index].idx,
    })

    if (postMainPageItemDeleteResponse.error) {
      console.log(postMainPageItemDeleteResponse.error)
      return
    } else {
      const deletedItems = data.filter((item) => item.idx !== data[index].idx)
      setItemList(deletedItems)
      console.log(postMainPageItemDeleteResponse)
    }
  }

  const handleItemSubmit = async (e) => {
    e.preventDefault()
    for (const item of itemList) {
      await updateItem(item)
    }
    await getMainPageItemApi()
    setIsItemEdit((prev) => !prev)
  }

  const handlePrevClick = (data, setData, index, field) => {
    const updatedItems = [...data]
    const changedOrder = updatedItems[index][field]
    updatedItems[index][field] = updatedItems[index - 1][field]
    updatedItems[index - 1][field] = changedOrder
    setData(updatedItems)
  }
  const handleNextClick = (data, setData, index, field) => {
    const updatedItems = [...data]
    const changedOrder = updatedItems[index][field]
    updatedItems[index][field] = updatedItems[index + 1][field]
    updatedItems[index + 1][field] = changedOrder
    setData(updatedItems)
  }

  console.log('itemList')
  console.log(itemList)
  return (
    <div className='flex h-screen bg-sk-box004'>
      <SideMenuBar />
      <div className='w-full overflow-auto'>
        <div className='flex flex-col mr-[90px] my-[60px] gap-[30px] ml-[350px]'>
          <div className='flex flex-col gap-[30px]'>
            <div className='flex gap-[30px] items-center p-[20px] bg-sk-box002 rounded-[10px] w-full shadow-sk'>
              <p className='text-h3-bold'>메인페이지</p>
            </div>
            {/* 배경사진 */}
            <div className='bg-sk-box002 p-[30px] flex gap-[30px] rounded-[10px] shadow-sk'>
              <form onSubmit={handleBgImgSubmit}>
                <div className='flex flex-col gap-[8px]'>
                  <div className='flex gap-[20px] items-center'>
                    <p className='text-h3-semibold text-sk-default'>배경사진</p>
                    <div className='flex gap-[12px] items-center'>
                      <button
                        type='button'
                        onClick={() => {
                          setIsBgImgEdit((prev) => !prev)
                        }}
                        className={`${isBgImgEdit ? 'btn-primary_small' : 'hidden'}`}
                      >
                        수정
                      </button>
                      <button
                        type='submit'
                        className={`${isBgImgEdit ? 'hidden' : 'btn-primary_small'}`}
                      >
                        완료
                      </button>
                    </div>
                  </div>
                  <div className='flex flex-col gap-[20px]'>
                    <ImgPreview
                      label={`배경 사진`}
                      isEdit={isBgImgEdit}
                      itemImg={bgImgList.imgUrl}
                      onImageChange={(ImgUrl) => updateBgImg(bgImgList, ImgUrl)}
                      imgSize={''}
                    />

                    <div className='flex flex-col gap-[8px]'>
                      <div className='flex gap-[12px] items-center px-[8px]'>
                        <p className='text-body2-bold text-sk-info min-w-[45px] shrink-0'>제목</p>
                        <p className={`${isBgImgEdit ? 'text-body2-medium' : 'hidden'}`}>
                          {bgImgList.title}
                        </p>
                        <input
                          type='text'
                          className={`border rounded-[10px] ${
                            isBgImgEdit
                              ? 'hidden'
                              : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px] items-end'
                          }`}
                          onChange={(e) => setBgImgList({ ...bgImgList, title: e.target.value })}
                          value={bgImgList.title}
                          required
                        />
                      </div>
                      <div className='flex gap-[12px] items-center px-[8px]'>
                        <p className='text-body2-bold text-sk-info min-w-[45px] shrink-0'>부제목</p>
                        <p className={`${isBgImgEdit ? 'text-body2-medium' : 'hidden'}`}>
                          {bgImgList.subTitle}
                        </p>
                        <input
                          type='text'
                          className={`border rounded-[10px] ${
                            isBgImgEdit
                              ? 'hidden'
                              : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px] items-end'
                          }`}
                          onChange={(e) => setBgImgList({ ...bgImgList, subTitle: e.target.value })}
                          value={bgImgList.subTitle}
                          required
                        />
                      </div>
                      <div className='flex gap-[12px] items-center px-[8px]'>
                        <p className='text-body2-bold text-sk-info min-w-[45px] shrink-0'>본문</p>
                        <p className={`${isBgImgEdit ? 'text-body2-medium' : 'hidden'}`}>
                          {bgImgList.text}
                        </p>
                        <input
                          type='text'
                          className={`border rounded-[10px] ${
                            isBgImgEdit
                              ? 'hidden'
                              : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px] items-end'
                          }`}
                          onChange={(e) => setBgImgList({ ...bgImgList, text: e.target.value })}
                          value={bgImgList.text}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* 배너사진 */}
            <div className='bg-sk-box002 p-[30px] flex flex-col gap-[30px] rounded-[10px] shadow-sk'>
              <div className='flex flex-col gap-[12px]'>
                <div className='flex gap-[20px] items-center'>
                  <p className='text-h3-semibold text-sk-default'>배너사진</p>
                  <div className='flex gap-[12px] items-center'>
                    <button
                      type='button'
                      onClick={() => {
                        setIsBannerImgEdit((prev) => !prev)
                      }}
                      className={`${isBannerImgEdit ? 'btn-primary_small' : 'hidden'}`}
                    >
                      수정
                    </button>
                    <button
                      type='button'
                      onClick={addBannerInput}
                      className={`${isBannerImgEdit ? 'hidden' : 'btn-primary_small'}`}
                    >
                      추가
                    </button>
                    <button
                      type='submit'
                      onClick={handleBannerImgSubmit}
                      className={`${isBannerImgEdit ? 'hidden' : 'btn-primary_small'}`}
                    >
                      완료
                    </button>
                  </div>
                </div>
                {/* 기존 배너사진 */}
                <div className='grid grid-cols-2 gap-[12px]'>
                  {bannerImgList.map((item, index) => (
                    <div key={index} className='flex gap-[12px] items-center'>
                      <div className='flex items-center gap-[12px]'>
                        <p className='text-body1-semibold text-sk-sub min-w-[5px]'>{item.order}</p>
                        <ImgPreview
                          label={`bannerImg- ${index + 1}`}
                          isEdit={isBannerImgEdit}
                          itemImg={item.imgUrl}
                          onImageChange={(ImgUrl) =>
                            updateImg(bannerImgList, setBannerImgList, index, ImgUrl)
                          }
                          imgSize={''}
                          onDelete={() => {
                            deleteBannerImg(bannerImgList, index)
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {/* 추가 배너사진 */}
                  {newBannerImgList.map((item, index) => (
                    <div key={index} className='flex gap-[12px] items-center'>
                      <div className='flex items-center gap-[12px] justify-center'>
                        <p className='text-body1-semibold text-sk-sub min-w-[5px]'>{item.order}</p>
                        <ImgPreview
                          label={`bannerImg- ${index + 1}`}
                          isEdit={isBannerImgEdit}
                          itemImg={item.imgUrl}
                          onImageChange={(ImgUrl) =>
                            updateImg(newBannerImgList, setNewBannerImgList, index, ImgUrl)
                          }
                          imgSize={''}
                          onDelete={() => deleteBannerInput(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* 아이템 */}
            <div className='bg-sk-box002 p-[30px] flex flex-col gap-[30px] rounded-[10px] shadow-sk '>
              <form onSubmit={handleItemSubmit}>
                <div className='flex flex-col'>
                  <div className='flex gap-[20px] items-center'>
                    <p className='text-h3-semibold'>partners</p>
                    <div className='flex gap-[12px] items-center'>
                      <button
                        type='button'
                        onClick={() => {
                          setIsItemEdit((prev) => !prev)
                        }}
                        className={`${isItemEdit ? 'btn-primary_small' : 'hidden'}`}
                      >
                        수정
                      </button>
                      <button
                        type='button'
                        onClick={openModal}
                        className={`${isItemEdit ? 'hidden' : 'btn-primary_small'}`}
                      >
                        추가
                      </button>
                      <button
                        type='submit'
                        className={`${isItemEdit ? 'hidden' : 'btn-primary_small'}`}
                      >
                        완료
                      </button>
                      {modalOpen && (
                        <ModalAddPartners
                          setModalOpen={setModalOpen}
                          order={itemOrder(itemList)}
                          setItemList={setItemList}
                        />
                      )}
                    </div>
                  </div>
                  {itemList.map((item, index) => (
                    <div
                      key={index}
                      className='flex gap-[20px] w-full border-b-[1px] border-sk-border py-[10px] px-[20px] items-center'
                    >
                      <div className='flex gap-[12px] items-center'>
                        <div className='flex flex-col items-center '>
                          {isItemEdit && (
                            <img
                              src={item.imgUrl}
                              alt='preview-img'
                              className='w-[150px] h-[100px]'
                            />
                          )}
                          {!isItemEdit && (
                            <ImgPreview
                              label={`itemImg- ${index + 1}`}
                              isEdit={isItemEdit}
                              itemImg={item.imgUrl}
                              onImageChange={(imgUrl) =>
                                updateImg(itemList, setItemList, index, imgUrl)
                              }
                              imgSize={`w-[150px] h-[100px]`}
                            />
                          )}
                        </div>
                      </div>

                      {item.type === 1 && (
                        <div className='flex flex-col justify-center gap-[8px] w-full'>
                          <div className='flex gap-[12px] items-center'>
                            <p className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                              제목
                            </p>
                            <p
                              className={`rounded-[10px] ${
                                isItemEdit ? 'text-body2-medium' : 'hidden'
                              }`}
                            >
                              {item.title}
                            </p>
                            <input
                              type='text'
                              value={item.title}
                              className={`border rounded-[10px] ${
                                isItemEdit
                                  ? 'hidden'
                                  : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                              }`}
                              onChange={(e) =>
                                handleInputChange(itemList, setItemList, index, 'title', e)
                              }
                              required
                            />
                          </div>
                          <div className='flex gap-[12px] items-start'>
                            <p className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                              부제목
                            </p>
                            <div className={`${isItemEdit ? 'text-body2-medium' : 'hidden'}`}>
                              {item.subTitle.split('|').map((line, lineIndex) => (
                                <p key={lineIndex}>{line}</p>
                              ))}
                            </div>
                            <textarea
                              value={item.subTitle.split('|').join('\n')}
                              rows={4}
                              onChange={(e) =>
                                handleTextChange(itemList, setItemList, index, 'subTitle', e)
                              }
                              className={` ${
                                isItemEdit
                                  ? 'hidden'
                                  : 'text-body2-medium border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                              }`}
                              required
                            />
                          </div>
                        </div>
                      )}
                      <div className='flex flex-col gap-[8px]'>
                        <button
                          type='button'
                          onClick={() => deleteItem(itemList, index)}
                          className={`${isItemEdit ? 'hidden' : 'btn-primary_mini'}`}
                        >
                          X
                        </button>
                        {index !== 0 && (
                          <button
                            type='button'
                            onClick={async () => {
                              handlePrevClick(itemList, setItemList, index, 'order')
                              for (const item of itemList) {
                                await updateItem(item)
                              }
                              await getMainPageItemApi()
                            }}
                            className={`${isItemEdit ? 'hidden' : 'btn-primary_mini'}`}
                          >
                            <img src={upIcon} alt='' />
                          </button>
                        )}
                        {index !== itemList.length - 1 && (
                          <button
                            type='button'
                            onClick={async () => {
                              handleNextClick(itemList, setItemList, index, 'order')
                              for (const item of itemList) {
                                await updateItem(item)
                              }
                              await getMainPageItemApi()
                            }}
                            className={`${isItemEdit ? 'hidden' : 'btn-primary_mini'}`}
                          >
                            <img src={downIcon} alt='' />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
