import { useEffect, useState } from 'react'
import SideMenuBar from '../components/SideMenuBar.jsx'
import ImgPreview from '../components/ImgPreview.jsx'
import {
  getBusiness,
  getBusinessItem,
  postBusinessItemAdd,
  postBusinessItemDelete,
  postBusinessItemDetailAdd,
  postBusinessItemDetailDelete,
  postBusinessItemDetailUpdate,
  postBusinessItemUpdate,
} from '../apis/business.ts'

function Business() {
  useEffect(() => {
    getBusinessApi()
    getBusinessItemApi()
  }, [])

  // 수정 / 완료 상태
  const [isEdit, setIsEdit] = useState(true)

  // 비즈니스 페이지 전체 정보 - 세부 아이템
  const [businessItemDetail, setBusinessItemDetail] = useState([])
  const getBusinessApi = async () => {
    const getBusinessResponse = await getBusiness()

    if (getBusinessResponse.error) {
      console.log(getBusinessResponse)
      return
    } else {
      setBusinessItemDetail(getBusinessResponse.data)
    }
  }

  // 비즈니스 페이지 아이템 정보
  const [businessItem, setBusinessItem] = useState([])
  const getBusinessItemApi = async () => {
    const getBusinessItemResponse = await getBusinessItem()

    if (getBusinessItemResponse.error) {
      console.log(getBusinessItemResponse)
      return
    } else {
      setBusinessItem(getBusinessItemResponse.data)
    }
  }

  /***********************************/
  /******** 비즈니스 페이지 아이템 *********/
  /***********************************/

  // 비즈니스 페이지 아이템 추가
  const [newItemList, setNewItemList] = useState([])

  const addItemInput = () => {
    if (businessItem.length + newItemList.length < 4) {
      // 새로운 아이템 추가
      const newItemOrder =
        businessItem.length > 0 ? businessItem[businessItem.length - 1].order + 1 : 1

      const newBusinessIdx =
        newItemList.length > 0
          ? newItemList[newItemList.length - 1].idx + 1
          : businessItem[businessItem.length - 1].idx + 1

      const newItem = {
        idx: newBusinessIdx,
        order: newItemOrder,
        name: '',
      }

      setNewItemList((prev) => [...prev, newItem])
      return newItem
    }
  }

  const addItem = async (data) => {
    const postBusinessItemAddResponse = await postBusinessItemAdd(data)
    if (postBusinessItemAddResponse.error) {
      console.log(postBusinessItemAddResponse.error)
      return
    } else {
      setBusinessItem((prev) => [...prev, data])
    }
  }
  // 비즈니스 아이템 수정
  const updateBusinessItem = async (data) => {
    for (const item of data) {
      const updatedBusinessItems = {
        idx: item.idx,
        name: item.name,
        order: item.order,
      }
      const postBusinessItemUpdateResponse = await postBusinessItemUpdate(updatedBusinessItems)
      if (postBusinessItemUpdateResponse.error) {
        console.log(postBusinessItemUpdateResponse.error)
        alert(postBusinessItemUpdateResponse.message)
        return
      }
    }
  }
  /***************************************/
  /******** 비즈니스 페이지 세부 아이템 *********/
  /***************************************/

  // 비즈니스 페이지 세부 아이템 추가

  const [newItemDetailList, setNewItemDetailList] = useState([])
  const nextOrder = (data, newData) => {
    const allItems = [...data, ...newData]
    if (allItems.length === 0) return 1
    return allItems[allItems.length - 1].order + 1
  }
  const addItemDetailInput = (data, itemIndex) => {
    const allItems = [...businessItemDetail, ...newItemDetailList]
    if (
      allItems.filter((detailItem) => data[itemIndex].idx === detailItem.businessIdx).length < 2
    ) {
      setNewItemDetailList((prev) => [
        ...prev,
        {
          businessIdx: data[itemIndex].idx,
          title: '',
          subTitle: '',
          content: '',
          order: nextOrder(businessItemDetail, newItemDetailList),
        },
      ])
    }
  }

  const deleteItemDetailInput = (index) => {
    const updatedItems = newItemDetailList.filter((_, itemIndex) => itemIndex !== index)
    setNewItemDetailList(updatedItems)
  }

  // 세부 아이템 추가
  const addDetail = async (data) => {
    const postBusinessItemDetailAddResponse = await postBusinessItemDetailAdd(data)
    if (postBusinessItemDetailAddResponse.error) {
      console.log(postBusinessItemDetailAddResponse.error)
      return
    } else {
      setBusinessItem((prev) => [...prev, data])
    }
  }

  // 비즈니스 세부 아이템 수정
  const updateBusinessItemDetail = async (data) => {
    const postBusinessItemDetailUpdateResponse = await postBusinessItemDetailUpdate(data)
    if (postBusinessItemDetailUpdateResponse.error) {
      console.log(postBusinessItemDetailUpdateResponse.error)
      alert(postBusinessItemDetailUpdateResponse.message)
      return
    }
  }
  // 비즈니스 페이지 아이템 삭제
  const deleteItem = async (data, index) => {
    const postBusinessItemDeleteResponse = await postBusinessItemDelete({
      idx: data[index].idx,
    })

    if (postBusinessItemDeleteResponse.error) {
      console.log(postBusinessItemDeleteResponse.error)
      return
    } else {
      const deletedItems = data.filter((item) => item.idx !== data[index].idx)
      setBusinessItem(deletedItems)
    }
  }
  // 비즈니스 페이지 세부 아이템 삭제
  const deleteItemDetail = async (data, index) => {
    const postBusinessItemDetailDeleteResponse = await postBusinessItemDetailDelete({
      idx: data[index].idx,
    })

    if (postBusinessItemDetailDeleteResponse.error) {
      console.log(postBusinessItemDetailDeleteResponse.error)
      return
    } else {
      const deletedItemDetails = data.filter((item) => item.idx !== data[index].idx)
      setBusinessItemDetail(deletedItemDetails)
    }
  }

  const updateImage = (data, setData, itemIndex, imgSrc) => {
    const updatedItems = data.map((item, index) => {
      if (index === itemIndex) {
        return { ...item, imgUrl: imgSrc }
      }
      return item
    })

    setData(updatedItems)
  }

  const handleInputChange = (data, setData, itemIndex, field, e) => {
    const updatedItems = data.map((item, index) => {
      if (index === itemIndex) {
        return { ...item, [field]: e.target.value }
      }
      return item
    })
    setData(updatedItems)
  }

  const handleTextChange = (data, setData, itemIndex, field, e) => {
    const joinContent = e.target.value.split('\n').join('|')
    const updatedItems = data.map((item, index) => {
      if (index === itemIndex) {
        return { ...item, [field]: joinContent }
      }
      return item
    })
    setData(updatedItems)
  }

  const onClickEditOrSubmit = async (e) => {
    e.preventDefault()
    await updateBusinessItem(businessItem)

    businessItem.forEach((item, index) => {
      const hasDetails =
        businessItemDetail.filter((detailItem) => item.idx === detailItem.businessIdx).length > 0 ||
        newItemDetailList.filter((detailItem) => item.idx === detailItem.businessIdx).length > 0

      if (!hasDetails) {
        deleteItem(businessItem, index)
      }
    })

    await getBusinessItemApi()

    for (const detailItem of newItemDetailList) {
      await addDetail(detailItem)
    }

    for (const item of businessItem) {
      for (const detailItem of businessItemDetail) {
        if (item.idx === detailItem.businessIdx) {
          await updateBusinessItemDetail(detailItem)
        }
      }
    }
    await getBusinessApi()

    setNewItemList([])
    setNewItemDetailList([])
    setIsEdit((prev) => !prev)

    window.location.reload()
  }

  return (
    <div className='flex h-screen bg-sk-box004'>
      <SideMenuBar />
      <div className='w-full overflow-auto'>
        <form onSubmit={(e) => onClickEditOrSubmit(e)}>
          <div className='flex flex-col mr-[90px] my-[60px] gap-[30px] ml-[350px]'>
            <div className='flex gap-[30px] items-center p-[20px] bg-sk-box002 rounded-[10px] w-full shadow-sk'>
              <p className='text-h3-bold'>비즈니스</p>
              <div className='flex gap-[12px]'>
                <button
                  type='button'
                  onClick={() => setIsEdit((prev) => !prev)}
                  className={`${isEdit ? 'btn-primary_small' : 'hidden'}`}
                >
                  수정
                </button>
                {!isEdit && (
                  <button
                    type='button'
                    onClick={async () => {
                      const newItem = addItemInput()
                      if (newItem) {
                        await updateBusinessItem(businessItem)
                        await addItem(newItem)
                        setNewItemList([])
                        await getBusinessItemApi()
                      }
                    }}
                    className={`btn-primary_small ${businessItem.length >= 4 ? 'hidden' : ''}`}
                  >
                    추가
                  </button>
                )}
                <button type='submit' className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}>
                  완료
                </button>
              </div>
            </div>
            <div className='flex flex-col gap-[30px] w-full'>
              {businessItem.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className='bg-sk-box002 p-[30px] flex flex-col gap-[20px] rounded-[10px] shadow-sk w-full'
                >
                  <div className='flex gap-[12px] items-center'>
                    <p className='text-body2-bold'>Item Name</p>
                    <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>{item.name}</p>
                    <input
                      type='text'
                      className={`border rounded-[10px] ${
                        isEdit
                          ? 'hidden'
                          : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                      }`}
                      value={item.name}
                      onChange={(e) =>
                        handleInputChange(businessItem, setBusinessItem, itemIndex, 'name', e)
                      }
                      required
                    />
                    <button
                      type='button'
                      onClick={() => deleteItem(businessItem, itemIndex)}
                      className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
                    >
                      X
                    </button>
                  </div>
                  {/* 세부 아이템 */}
                  <div className='flex flex-col w-full'>
                    <div className='flex gap-[12px] items-center w-full'>
                      <p className='text-body2-bold'>세부 아이템</p>
                      {!isEdit && (
                        <button
                          type='button'
                          onClick={() => addItemDetailInput(businessItem, itemIndex)}
                          className='btn-primary_small'
                        >
                          추가
                        </button>
                      )}
                    </div>
                    {/* 기존 세부 아이템 */}
                    {businessItemDetail.map(
                      (detailItem, detailItemIndex) =>
                        item.idx === detailItem.businessIdx && (
                          <div className='flex'>
                            <div
                              key={detailItemIndex}
                              className='flex gap-[12px] w-full border-b-[1px] border-sk-border py-[10px] px-[20px] items-center'
                            >
                              {isEdit && (
                                <img
                                  src={detailItem.imgUrl}
                                  alt='preview-img'
                                  className='w-[300px] h-[200px]'
                                />
                              )}
                              {!isEdit && (
                                <ImgPreview
                                  itemIndex={itemIndex}
                                  label={`이미지 ${detailItemIndex + 1}`}
                                  isEdit={isEdit}
                                  itemImg={detailItem.imgUrl}
                                  onImageChange={(imgUrl) =>
                                    updateImage(
                                      businessItemDetail,
                                      setBusinessItemDetail,
                                      detailItemIndex,
                                      imgUrl
                                    )
                                  }
                                  imgSize={`w-[300px] h-[200px]`}
                                />
                              )}
                              <div className='flex flex-col gap-[8px] w-full'>
                                <div className='flex gap-[12px] items-center'>
                                  <label className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                                    제목
                                  </label>
                                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                                    {detailItem.title}
                                  </p>
                                  <input
                                    type='text'
                                    className={`border rounded-[10px] ${
                                      isEdit
                                        ? 'hidden'
                                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                                    }`}
                                    value={!isEdit ? detailItem.title : ''}
                                    onChange={(e) => {
                                      handleInputChange(
                                        businessItemDetail,
                                        setBusinessItemDetail,
                                        detailItemIndex,
                                        'title',
                                        e
                                      )
                                    }}
                                    required
                                  />
                                </div>
                                <div className='flex gap-[12px] items-center'>
                                  <label className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                                    부제목
                                  </label>
                                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                                    {detailItem.subTitle}
                                  </p>
                                  <input
                                    type='text'
                                    className={`border rounded-[10px] ${
                                      isEdit
                                        ? 'hidden'
                                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                                    }`}
                                    value={!isEdit ? detailItem.subTitle : ''}
                                    onChange={(e) => {
                                      handleInputChange(
                                        businessItemDetail,
                                        setBusinessItemDetail,
                                        detailItemIndex,
                                        'subTitle',
                                        e
                                      )
                                    }}
                                    required
                                  />
                                </div>
                                <div className='flex gap-[12px] items-start'>
                                  <label className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                                    내용
                                  </label>
                                  <div className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                                    {detailItem.content.split('|').map((line, lineIndex) => (
                                      <p key={lineIndex}>{line}</p>
                                    ))}
                                  </div>
                                  <textarea
                                    value={detailItem.content.split('|').join('\n')}
                                    className={` ${
                                      isEdit
                                        ? 'hidden'
                                        : 'text-body2-medium border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                                    }`}
                                    rows={4}
                                    onChange={(e) => {
                                      handleTextChange(
                                        businessItemDetail,
                                        setBusinessItemDetail,
                                        detailItemIndex,
                                        'content',
                                        e
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                              <button
                                type='button'
                                onClick={() =>
                                  deleteItemDetail(businessItemDetail, detailItemIndex)
                                }
                                className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
                              >
                                X
                              </button>
                            </div>
                          </div>
                        )
                    )}
                    {/* 추가 세부 아이템 */}
                    {newItemDetailList.map(
                      (detailItem, detailItemIndex) =>
                        item.idx === detailItem.businessIdx && (
                          <div className='flex'>
                            <div
                              key={detailItemIndex}
                              className='flex gap-[12px] w-full border-b-[1px] border-sk-border py-[10px] px-[20px] items-center'
                            >
                              {isEdit && (
                                <img
                                  src={detailItem.imgUrl}
                                  alt='preview-img'
                                  className='w-[300px] h-[200px]'
                                />
                              )}
                              {!isEdit && (
                                <ImgPreview
                                  itemIndex={itemIndex}
                                  label={`이미지 ${detailItemIndex + 1}`}
                                  isEdit={isEdit}
                                  itemImg={detailItem.imgUrl}
                                  onImageChange={(imgUrl) =>
                                    updateImage(
                                      newItemDetailList,
                                      setNewItemDetailList,
                                      detailItemIndex,
                                      imgUrl
                                    )
                                  }
                                  imgSize={`w-[300px] h-[200px]`}
                                />
                              )}
                              <div className='flex flex-col gap-[8px] w-full'>
                                <div className='flex gap-[12px] items-center'>
                                  <label className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                                    제목
                                  </label>
                                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                                    {detailItem.title}
                                  </p>
                                  <input
                                    type='text'
                                    className={`border rounded-[10px] ${
                                      isEdit
                                        ? 'hidden'
                                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                                    }`}
                                    value={!isEdit ? detailItem.title : ''}
                                    onChange={(e) => {
                                      handleInputChange(
                                        newItemDetailList,
                                        setNewItemDetailList,
                                        detailItemIndex,
                                        'title',
                                        e
                                      )
                                    }}
                                    required
                                  />
                                </div>
                                <div className='flex gap-[12px] items-center'>
                                  <label className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                                    부제목
                                  </label>
                                  <p className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                                    {detailItem.subTitle}
                                  </p>
                                  <input
                                    type='text'
                                    className={`border rounded-[10px] ${
                                      isEdit
                                        ? 'hidden'
                                        : 'text-body2-medium w-[402px] rounded-[10px] bg-sk-box002 px-[15px] py-[10px]'
                                    }`}
                                    value={!isEdit ? detailItem.subTitle : ''}
                                    onChange={(e) => {
                                      handleInputChange(
                                        newItemDetailList,
                                        setNewItemDetailList,
                                        detailItemIndex,
                                        'subTitle',
                                        e
                                      )
                                    }}
                                    required
                                  />
                                </div>
                                <div className='flex gap-[12px] items-start'>
                                  <label className='text-body2-bold min-w-[45px] text-sk-info shrink-0'>
                                    내용
                                  </label>
                                  <div className={`${isEdit ? 'text-body2-medium' : 'hidden'}`}>
                                    {detailItem.content.split('|').map((line, lineIndex) => (
                                      <p key={lineIndex}>{line}</p>
                                    ))}
                                  </div>
                                  <textarea
                                    value={detailItem.content.split('|').join('\n')}
                                    className={` ${
                                      isEdit
                                        ? 'hidden'
                                        : 'text-body2-medium border rounded-[10px] px-[15px] py-[10px] w-full resize-none'
                                    }`}
                                    rows={4}
                                    onChange={(e) => {
                                      handleTextChange(
                                        newItemDetailList,
                                        setNewItemDetailList,
                                        detailItemIndex,
                                        'content',
                                        e
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                              <button
                                type='button'
                                onClick={() => deleteItemDetailInput(detailItemIndex)}
                                className={`${isEdit ? 'hidden' : 'btn-primary_small'}`}
                              >
                                X
                              </button>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Business
