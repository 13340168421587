import { CommonResponse, get, post } from './common.ts'

/**
 * (01)
 * 비즈니스 페이지 전체 정보
 */

// Get /business
interface GetBusinessResponse {
  idx: number
  businessIdx: number
  name: string
  title: string
  subTitle: string
  content: string
  imgUrl: string
  order: number
}

export const getBusiness = async (): Promise<CommonResponse<GetBusinessResponse>> => {
  return await get('/business')
}

/**
 * (02)
 * 비즈니스 페이지 아이템 정보
 */

// Get /business/item
interface GetBusinessItemResponse {
  idx: number
  name: string
  order: number
}

export const getBusinessItem = async (): Promise<CommonResponse<GetBusinessItemResponse>> => {
  return await get('/business/item')
}

/**
 *(03)
 *비즈니스 페이지 아이템 추가
 */

// POST /business/item/add
interface PostBusinessItemAddRequest {
  idx: number
  name: string
  order: number
}

export const postBusinessItemAdd = async (
  body: PostBusinessItemAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/business/item/add', body)
}

/**
 *(04)
 *비즈니스 페이지 아이템 수정
 */

// POST /business/item/update
interface PostBusinessItemUpdateRequest {
  idx: number
  name: string
  order: number
}

export const postBusinessItemUpdate = async (
  body: PostBusinessItemUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/business/item/update', body)
}

/**
 *(05)
 *비즈니스 페이지 아이템 삭제
 */

// POST /business/item/delete
interface PostBusinessItemDeleteRequest {
  idx: number
}

export const postBusinessItemDelete = async (
  body: PostBusinessItemDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/business/item/delete', body)
}

/**
 *(06)
 *각 비즈니스 아이템 세부 아이템 추가
 */

// POST /business/item/detail/add
interface PostBusinessItemDetailAddRequest {
  businessIdx: number
  title: string
  subTitle: string
  content: string
  imgUrl: string
  order: number
}

export const postBusinessItemDetailAdd = async (
  body: PostBusinessItemDetailAddRequest
): Promise<CommonResponse<null>> => {
  return await post('/business/item/detail/add', body)
}

/**
 *(07)
 *각 비즈니스 아이템 세부 아이템 수정
 */

// POST /business/item/detail/update
interface PostBusinessItemDetailUpdateRequest {
  idx: number
  title: string
  subTitle: string
  content: string
  order: number
  imgUrl: string
}

export const postBusinessItemDetailUpdate = async (
  body: PostBusinessItemDetailUpdateRequest
): Promise<CommonResponse<null>> => {
  return await post('/business/item/detail/update', body)
}

/**
 *(08)
 *각 비즈니스 아이템 세부 아이템 삭제
 */

// POST /business/item/detail/delete
interface PostBusinessItemDetailDeleteRequest {
  idx: number
}

export const postBusinessItemDetailDelete = async (
  body: PostBusinessItemDetailDeleteRequest
): Promise<CommonResponse<null>> => {
  return await post('/business/item/detail/delete', body)
}
