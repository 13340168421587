import { CommonResponse, post } from './common.ts'

// POST /admin/login

interface PostAdminLoginRequest {
  id: string
  password: string
}

export const postAdminLogin = async (
  body: PostAdminLoginRequest
): Promise<CommonResponse<null>> => {
  return await post('/admin/login', body)
}
