import { CommonResponse, post } from './common.ts'

/**
 * (01)
 * 이미지 파일 업로드
 */

// POST /util/file-upload
interface PostUtilFileUploadResponse {
  image: File
}

export const postUtilFileUpload = async (
  body: PostUtilFileUploadResponse
): Promise<CommonResponse<null>> => {
  let form = new FormData()
  form.append('image', body.image)
  return await post('/util/file-upload', form)
}
